import { CourseLightModel, CourseModel, SessioncontactModel, SessionLightModel, SessionModel } from '@24hbs/utils';
import {
  B2B_FONDO_NUOVE_COMPETENZE,
  B2B_FORMAZIONE_INTERAZIENDALE_A_CATALOGO,
  B2B_FORMAZIONE_SU_MISURA,
  B2B_HR_FOR_BREAKFAST,
  B2B_LE_MASTERCLASS
} from '$lib/utils/custom-courses';
import { MBAUtils } from '$lib/utils/mba-utils';
import { PUBLIC_BASE_URL } from '$env/static/public';

const mapSlug = (slug: string) => {
  if (slug === MBAUtils.NORMAL_SLUG) {
    return MBAUtils.ORIGINAL_SLUG;
  }
  return slug;
};

export interface CourseList {
  courses: CourseModel[];
}

export interface CourseLightList {
  courses: CourseLightModel[];
}

/**
 * Get normal slug from original slug
 * @param slug
 * @returns string
 */
export const getNormalSlug = (slug: string): string => {
  let checkSlug: string = slug;
  if (slug.startsWith('/formazione/')) {
    checkSlug = slug.replace('/formazione/', '');
  }
  if (slug.startsWith('formazione/')) {
    checkSlug = slug.replace('formazione/', '');
  }
  if (checkSlug === MBAUtils.ORIGINAL_SLUG) {
    return MBAUtils.NORMAL_SLUG;
  }
  return slug;
}

const fetchCourse = async (url: string, _fetch: {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
  (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
}): Promise<CourseModel | null> => {
  try {
    let res = await _fetch(url);
    if (res.ok) {
      console.info(`Course at ${url} found.`);
      return CourseModel.fromJSON(await res.json());
    }

    console.warn(`[!] Course at ${url} not found.`, await res.text());

    return null;
  } catch (e) {
    console.warn(`[!] Course at ${url} not found.`, e);
    return null;
  }
}

export const getCourseBySlug = async (slug: string, _fetch: {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
  (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
}): Promise<CourseModel | null> => {
  return fetchCourse(`/api/v1/courses/sl/${mapSlug(slug)}.json`, _fetch);
};

export const getCourseByProductCode = async (productCode: string, _fetch: {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
  (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response>;
}): Promise<CourseModel | null> => {
  return fetchCourse(`/api/v1/courses/in/${productCode}.json`, _fetch);
};

export const getB2BFondoNuoveCompetenze = async (): Promise<CourseModel> => Promise.resolve(B2B_FONDO_NUOVE_COMPETENZE.model);

export const getB2BFormazioneInteraziendaleACatalogo = async (): Promise<CourseModel> => Promise.resolve(B2B_FORMAZIONE_INTERAZIENDALE_A_CATALOGO.model);

export const getB2BFormazioneSuMisura = async (): Promise<CourseModel> => Promise.resolve(B2B_FORMAZIONE_SU_MISURA.model);

export const getB2BHRforBreakfast = async (): Promise<CourseModel> => Promise.resolve(B2B_HR_FOR_BREAKFAST.model);

export const getB2BLeMasterclass = async (): Promise<CourseModel> => Promise.resolve(B2B_LE_MASTERCLASS.model);

export const getMBACourseData = async (): Promise<CourseModel | null> => {
  return new Promise((resolve) => {
    let course: CourseModel = new CourseModel();
    course.title = 'Executive MBA';
    course.slug = 'executive-mba';
    course.courseTypeFull = 'Executive MBA';
    course.category = 'mba';
    course.idMainSession = 10068;
    let selectedSession: SessionModel = new SessionModel();
    selectedSession.productCode = 'YZ22003000';
    selectedSession.title = 'Executive MBA';
    selectedSession.courseTypeFull = 'Executive MBA';
    selectedSession.slug = 'executive-mba';
    selectedSession.idSession = 10068;
    selectedSession.price = 0;
    selectedSession.category = 'mba';
    selectedSession.previewUrl = '/placeholders/mba/emba-hero.webp';
    selectedSession.brochureBooking = false;
    selectedSession.brochureAvailable = true;
    selectedSession.brochureUrl = `${PUBLIC_BASE_URL}mba/EMBA_15_12.pdf`;
    selectedSession.sessionContacts = SessioncontactModel.fromJson({
      editorialContact: 'Segreteria',
      editorialEmail: 'candidature@24orebs.com',
      editorialPhone: '02 3792 9700',

      marketingContact: '',
      marketingEmail: '',
      marketingPhone: '',

      customercareContact: '',
      customercareEmail: '',
      customercarePhone: '',
    });
    // New values
    // selectedSession.onSaleLabel = 'Advance booking';
    selectedSession.priceNoVat = 27900;
    // selectedSession.discountPrice = 23715;
    // selectedSession.onSale = true;
    // selectedSession.onSaleDetail = 'Prezzo speciale valido fino al 10/1/2022';
    course.addSession(selectedSession);
    resolve(course);
  });
};

export const lessionModeText = (lessionMode: string) => {
  switch (lessionMode.toLowerCase()) {
    case 'live streaming/milano':
      return 'Live Streaming o in aula a Milano';
    case 'live streaming/roma':
      return 'Live Streaming o in aula a Roma';
    case 'live streaming/catania':
      return 'Live Streaming o in aula a Catania';
    case 'live streaming + on demand':
      return 'Live Streaming + On Demand';
    case 'live streaming/milano e roma':
      return 'Live Streaming o in aula a Milano e a Roma';
    default:
      return lessionMode;
  }
};
/*

click(){
sendevent /dev/input/event4 4 4 589825
sendevent /dev/input/event4 1 272 1
sendevent /dev/input/event4 0 0 0
sendevent /dev/input/event4 4 4 589825
sendevent /dev/input/event4 1 272 0
sendevent /dev/input/event4 0 0 0
}

moveL(x, y){
sendevent /dev/input/event4 0 0 0
sendevent /dev/input/event4 2 0 100
sendevent /dev/input/event4 2 1 100

}

 */

export const lessonModeIcon = (lessonMode: string | undefined): string => {
  if (!lessonMode) {
    return 'ondemand';
  }

  switch (lessonMode.toLowerCase()) {
    case 'live streaming':
    case 'online':
      return 'live-streaming';
    case 'live streaming/roma':
    case 'live streaming/catania':
    case 'live streaming/milano':
      return 'mixed-mode';

    case 'aula':
    case 'roma':
    case 'milano':
    case 'catania':
      return 'place';
    case 'on demand':
      return 'ondemand';
    default:
      return 'ondemand';
  }
};
export const lessonModeTooltip = (lessonMode: string): string => {
  switch (lessonMode.toLowerCase()) {
    case 'live streaming':
    case 'online':
      return 'Puoi seguire le lezioni da dove vuoi e dal device che preferisci (computer, smartphone, tablet e smart TV) interagendo con docenti e compagni.';
    case 'live streaming/roma':
    case 'live streaming/catania':
    case 'live streaming/milano':
      return 'Puoi seguire le lezioni in aula o, alternativamente,  da dove vuoi  e dal device che prefersci (computer, smartphone, tablet e smart TV) interagendo con docenti e compagni.';
    case 'live streaming + on demand':
      return 'Il corso è composto da due tipi di lezioni:<br><br>LIVE STREAMING<br>Puoi seguire queste lezioni  da dove vuoi e dal device che preferisci (computer, smartphone, tablet e smart TV) interagendo con docenti e compagni.<br><br>ON DEMAND<br>Puoi seguire queste lezioni quando vuoi, da dove vuoi, dal device che preferisci (computer, smartphone, tablet e smart TV) senza interagire con docenti e compagni.';
    case 'on demand':
      return 'Puoi seguire le lezioni interattive quando vuoi, da dove vuoi, dal device che preferisci (computer, smartphone, tablet e smart TV). Al superamento dei test di valutazione ricevi subito l’attestato.';
    case 'live streaming/milano e roma':
      return 'Puoi seguire le lezioni in aula o, alternativamente, da dove vuoi e dal device che preferisci (computer, smartphone, tablet e smart TV) interagendo con docenti e compagni.';
    default:
      return '';
  }
};

export const LessonMode = {
  isLiveStreaming: (session: SessionModel | SessionLightModel): boolean => {
    return session.lessionMode !== undefined && session.lessionMode !== null && session.lessionMode.toLowerCase() === 'live streaming';
  },

  isLiveStreamingAula: (session: SessionModel | SessionLightModel, place: string = null): boolean => {
    if (!place) {
      return (
        session.lessionMode !== undefined && session.lessionMode !== null &&
        (session.lessionMode.toLowerCase() == 'live streaming/roma' ||
          session.lessionMode.toLowerCase() == 'live streaming/catania' ||
          session.lessionMode.toLowerCase() == 'live streaming/milano' ||
          session.lessionMode.toLowerCase() == 'live streaming/milano e roma')
      );
    }
    if (place.toLowerCase() === 'roma') {
      return session.lessionMode !== undefined && session.lessionMode !== null && session.lessionMode.toLowerCase() === 'live streaming/roma';
    }
    if (place.toLowerCase() === 'catania') {
      return session.lessionMode !== undefined && session.lessionMode !== null && session.lessionMode.toLowerCase() === 'live streaming/catania';
    }
    if (place.toLowerCase() === 'milano') {
      return session.lessionMode !== undefined && session.lessionMode !== null && session.lessionMode.toLowerCase() === 'live streaming/milano';
    }
    if (place.toLowerCase() === 'milano e roma') {
      return session.lessionMode !== undefined && session.lessionMode !== null && session.lessionMode.toLowerCase() === 'live streaming/milano e roma';
    }
  },

  isLiveStreamingOnDemand: (session: SessionModel | SessionModel): boolean => {
    return session.lessionMode !== undefined && session.lessionMode !== null && session.lessionMode.toLowerCase() === 'live streaming + on demand';
  },

  isLiveStreamingMixed: (session: SessionModel | SessionLightModel): boolean => {
    if (session.lessionMode) {
      const lessionModeLower = session.lessionMode.toLowerCase();
      return lessionModeLower != 'live streaming' && lessionModeLower.startsWith('live streaming');
    }
  },
  isOnDemand: (session: SessionModel | SessionLightModel): boolean => {
    if (session.lessionMode !== undefined && session.lessionMode !== null) {
      const lessionModeLower = session.lessionMode.toLowerCase();
      return lessionModeLower === 'on demand' || lessionModeLower === 'online';
    }

    return false;
  },
  isAula: (session: SessionModel | SessionLightModel): boolean => {
    if (session.lessionMode !== undefined && session.lessionMode !== null) {
      const lessionModeLower = session.lessionMode.toLowerCase();
      return lessionModeLower === 'aula' || lessionModeLower === 'milano' || lessionModeLower === 'roma' || lessionModeLower === 'catania';
    }

    return false;
  },
};

export const getPageType = (path: string): string => {
  const courseTypeReg = [
    /(corsi\-executive)/,
    /(corsi\-laurea)|(corsi\-di\-laurea)/,
    /(corsi\-on\-demand)|(corsi\-ondemand)|(corsi\-on\-line)|(corsi\-online)/,
    /(executive\-master)|(master\-executive)/,
    /(executive\-mba)|(emba)/,
    /(master\-post\-laurea\-con\-stage)|(master\-post\-laurea\-con\-stage\-garantito)|(master\-post\-laurea)/,
    /(masterclass)/,
    /(mba\-full\-time)|(master\-mba\-full\-time)/,
    /(mba\-part\-time)|(master\-mba\-part\-time)/,
    /(master\-professional)|(professional\-master)/,
    /(study\-tour)|(studytour)/
  ];

  const courseCategoryReg = [
    /(marketing\-comunicazione\-digital\-e\-social\-media)|(self\-education\-mktg)/,
    /(ambiente\-energia\-e\-pa)|(ambiente\-energia\-e\-p\-a)|(ambiente\-energia\-pa)|(self\-education\-energia\-pa)/,
    /(amministrazione\-finanza\-e\-controllo)|(self\-education\-afc)/,
    /(arte\-cinema\-e\-design)|(self\-education\-arte)/,
    /(diritto\-e\-fisco)|(self\-education\-diritto\-fisco)/,
    /(food\-beverage\-e\-turismo)|(self\-education\-food)/,
    /(gestione\-di\-impresa\-export\-e\-internazionalizzazione)|(self\-education\-gsi)|(gestione\-dimpresa\-export\-e\-internazionalizzazione)/,
    /(hr\-lavoro\-e\-sviluppo\-manageriale)|(self\-education\-hr\-lavoro)/,
    /(lusso\-e\-moda)|(self\-education\-lusso)/,
    /(sanita\-pharma\-e\-biomed)|(self\-education\-sanita)/,
    /(sport)|(self\-education\-sport)/,
    /(vendite\-e\-retail)|(self\-education\-vendite\-retail)/,
  ];

  if (courseTypeReg.some((type) => type.test(path))) {
    return 'producttype_page';
  }

  if (courseCategoryReg.some((category) => category.test(path))) {
    return 'category_page';
  }

  if (path === '/tutti-i-percorsi') {
    return 'alltypes_page';
  }

  if (path === '/') {
    return 'home_page';
  }

  if (path?.includes('/lp')) {
    return 'landing_page';
  }

  return 'unknown_page';
};

export const isMarconiSession = (session: SessionModel | SessionLightModel): boolean => {
  return (
    session.admissionWithBuyAvailable != "0" && (session.certificationReleaseEntity.includes('UniMarconi - prodotto BS24') || session.certificationReleaseEntity.includes('UniMarconi - prodotto UniMarconi') || session.productCode === 'CZ22000100')
  );
};

export const isMarconiLaureaSession = (session: SessionModel | SessionLightModel): boolean => {
  return session.productCode === 'CZ22000100';
};

export const isEuroconferenceSession = (session: SessionModel | SessionLightModel): boolean => {
  return (
    session.certificationReleaseEntity.includes('Euroconference')
  );
};

export const isB2BCourse = (course: CourseModel | CourseLightModel | SessionModel | SessionLightModel) => {
  if (course instanceof SessionLightModel || course instanceof SessionModel) {
    return course.slug === B2B_FONDO_NUOVE_COMPETENZE.model.sessions[B2B_FONDO_NUOVE_COMPETENZE.model.idMainSession].slug;
  }
  return course.slug === B2B_FONDO_NUOVE_COMPETENZE.model.slug;
};

export const hasVatFreeSession = (course: SessionModel | SessionLightModel | CourseModel | CourseLightModel): boolean => {
  if (course instanceof SessionModel || course instanceof SessionLightModel) {
    return false;
  }

  let key = 'sessions';
  if (course instanceof CourseLightModel) {
    key = 'sessionLight';
  }

  let sessions: SessionLightModel[] | SessionModel[] = Object.values(course[key]);

  let result = false;

  sessions.forEach((session) => {
    if (session.vatFree == true) {
      result = true;
    }
  });

  return result;
};

export const getSessionFinalAmount = (session: SessionModel | SessionLightModel): number => {
  if (session.hasDiscount) {
    if (session.discountPercent) {
      return (session.priceNoVat ? session.priceNoVat : 0) * (1 - session.discountPercent / 100);
    }
    if (session.discountPrice) {
      return (session.priceNoVat ? session.priceNoVat : 0) - session.discountPrice;
    }
  }
  return session.priceNoVat ? session.priceNoVat : 0;
}