import type { GtmEventData, GtmTechMetricData, GtmProduct } from './types';
import type { IAuthStore, IUserInfo } from '$lib/utils/auth-store';
import type { SessionStore } from '$lib/utils/session-store';
import type { CookiesStore } from '$lib/utils/cookie-store';
import { getPageType } from '$lib/utils/course-utils';
import { get } from 'svelte/store';
import type { HomepageSlide } from '$lib/utils/homepage/slider/types';
import type { Page } from '@sveltejs/kit';

export enum MenuGtmEventId {
  MASTER_FULL_TIME_CON_STAGE = 10,
  MASTER_PART_TIME = 11,
  CORSI_PART_TIME = 12,
  CORSI_ON_DEMAND = 13,
  EXECUTIVE_MBA = 14,
  MASTER_EXECUTIVE = 15,
  MARKETING_COMUNICAZIONE_DIGITAL_E_SOCIAL_MEDIA = 16,
  GESTIONE_DI_IMPRESA_EXPORT_E_INTERNAZIONALIZZAZIONE = 17,
  HR_LAVORO_E_SVILUPPO_MANAGERIALE = 18,
  AMMINISTRAZIONE_FINANZA_E_CONTROLLO = 19,
  LUSSO_E_MODA = 20,
  VENDITE_E_RETAIL = 21,
  AMBIENTE_ENERGIA_E_PA = 22,
  SANITA_PHARMA_E_BIOMED = 23,
  ARTE_CINEMA_E_DESIGN = 24,
  DIRITTO_E_FISCO = 25,
  FOOD_BEVERAGE_E_TURISMO = 26,
  SPORT = 27,
  INIZIATIVE = 28,
  DOCENTI = 29,
  PARTNER = 30,
  COMMUNITY = 31,
  CORSI_INTENSIVI_STAGE = 32,
  CORSI_INTENSIVI = 33,
  OPEN_DAY = 34,
  PER_LE_IMPRESE = 35,
  JOB_FINDING = 36,
  SKILLING = 37,
  BRANDING = 38,
  CORSI_LAUREA = 39,
  B2B_OPPORTUNITA = 40,
  // MASTER_FULL_TIME_CON_CAREER_SERVICE = 41,
  B2B_OFFERTA = 42,
  CODING_ACADEMY = 43,
  MBA_FULL_TIME = 44,
  MASTERCLASS = 45,
  MASTER_UNIVERSITARI_CON_CFU = 46,
  MASTER_EUROCONFERENCE = 47,
  DIGITAL_ACADEMY = 48,

  EXECUTIVE_MASTER = 50,
  PROFESSIONAL_MASTER = 51,
  CORSI_EXECUTIVE = 52,
  MBA_PART_TIME = 53,
  MBA_EMBA = 54,

  // TOP MENU
  TOP_MENU_CHI_SIAMO = 1001,
  TOP_MENU_CONTATTACI = 1002,
  TOP_MENU_SEDI_MILANO = 1003,
  TOP_MENU_SEDI_ROMA = 1004,

  // PERCORSI
  PERCORSI_EXECUTIVE_MASTER = 200,
  PERCORSI_EXECUTIVE_MBA = 201,
  PERCORSI_MBA_PART_TIME = 202,
  PERCORSI_MBA_FULL_TIME = 203,
  PERCORSI_CORSI_EXECUTIVE = 204,
  PERCORSI_MASTER_POST_LAUREA_CON_STAGE = 205,
  PERCORSI_PROFESSIONAL_MASTER_CON_CFU = 206,
  PERCORSI_CORSO_DI_LAUREA_IN_DIGITAL_MARKETING = 207,
  PERCORSI_PROFESSIONAL_MASTER = 208,
  PERCORSI_CORSI_ONLINE = 209,
  PERCORSI_CORSI_EUROCONFERENCE = 210,
  PERCORSI_MASTERCLASS = 211,

  // PROGRAMMI
  PROGRAMMI_MASTER_FULL_TIME_CON_STAGE = 300,
  PROGRAMMI_EXECUTIVE_MASTER = 301,
  PROGRAMMI_PROFESSIONAL_MASTER = 302,
  PROGRAMMI_CORSI_EXECUTIVE = 303,
  PROGRAMMI_MASTER_PART_TIME = 304,
  PROGRAMMI_EXECUTIVE_MBA = 305,
  PROGRAMMI_MBA_FULL_TIME = 306,
  PROGRAMMI_MBA_PART_TIME = 307,
  PROGRAMMI_MBA_EMBA = 308,
  PROGRAMMI_MASTER_UNIVERSITARI_CON_CFU = 309,
  PROGRAMMI_DIGITAL_ACADEMY = 310,
  PROGRAMMI_CORSI_ON_DEMAND = 311,
  PROGRAMMI_MASTER_EUROCONFERENCE = 312,
  PROGRAMMI_MASTERCLASS = 313,

  // HAMBURGER MENU
  HAMBURGER_CHI_SIAMO = 101,
  HAMBURGER_CONTATTI = 102,
  HAMBURGER_SEDI = 103,
  HAMBURGER_FINANZIAMENTI = 104,
  HAMBURGER_DOCENTI = 105,
  HAMBURGER_PARTNERS = 106,
  HAMBURGER_NEWS = 107,
  HAMBURGER_FAQ = 108,
  HAMBURGER_REVIEW = 109,
  HAMBURGER_E_LEARNING = 110,
}

export enum GtmEventCategory {
  MENU_BAR = 'menu_bar',
  COOKIE = 'cookie',
}

export class GtmStore {
  private static userInfo: IUserInfo | null = null;
  private static sessionStore: SessionStore | null = null;
  private static isLoggedIn: boolean = false;
  private static pagePath: string = '/';
  private static authStore: IAuthStore | null = null;
  private static pageStore: Page | null = null;
  private static lastEvent: GtmEventData | GtmTechMetricData | null = null;
  private static emptyPageView = false;
  private static pushHostDone = false;
  private static cookiesStore: CookiesStore;
  private static cookieNoticeAccepted: boolean = false;
  private static companyStatus: boolean = false;

  /****************************/
  private static MAX_SIZE = 10;
  private static TIME_INACTIVE = 30; // In seconds
  private static interval: NodeJS.Timeout | null = null;
  private static productBatch: GtmProduct[] = [];
  /****************************/

  //Set ready at startup because GTM team wants
  private static gtmReady = true;

  private static tempEvents: (
    | GtmEventData
    | { event: string; page_path: string }
  )[] = [];

  public static initGtmStore(
    authStore: IAuthStore,
    sessionStore: SessionStore,
    cookiesStore: CookiesStore,
    pageStore: Page
  ) {
    GtmStore.authStore = authStore;
    GtmStore.pageStore = pageStore;
    GtmStore.sessionStore = sessionStore;
    GtmStore.cookiesStore = cookiesStore;

    if (GtmStore.authStore) {
      GtmStore.authStore.userInfo.subscribe((info: IUserInfo | null) => {
        GtmStore.userInfo = info;
      });
    }

    if (GtmStore.cookiesStore) {
      GtmStore.cookiesStore.cookies.subscribe((value) => {
        GtmStore.cookieNoticeAccepted = value.cookie_notice_accepted ? true : false;
      });
    }

    GtmStore.sessionStore.pendingUserInfo.subscribe((info) => {
      if (info !== null) {
        GtmStore.userInfo = {
          id: info.id,
          bsid: info.bsid,
          email: info.email,
          name: info.name,
          surname: info.lastname,
          fullname: '',
          subscribe: false,
          businessUseConsent: false,
          profilingConsent: false,
        };
      }
    });

    GtmStore.authStore.isLoggedIn.subscribe((loggedIn: boolean) => {
      GtmStore.isLoggedIn = loggedIn === true ? true : false;
    });
    GtmStore.sessionStore.companyStatus.subscribe((status) => {
      GtmStore.companyStatus = status === true ? true : false;
    });
    GtmStore.pageStore.subscribe((pageContext) => {
      GtmStore.pagePath = pageContext.path;
      GtmStore.pushPageView();
    });

    GtmStore.pushHostToGtm();
  }

  private static pushHostToGtm() {
    if (!GtmStore.pushHostDone && typeof window !== 'undefined') {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        originalLocation:
          document.location.protocol +
          '//' +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
      });
      GtmStore.pushHostDone = true;
    }
  }

  public static gtmIsReady() {
    GtmStore.gtmReady = true;
    if (GtmStore.tempEvents.length > 0) {
      GtmStore.pushPageView(true);
    }
  }

  public static pushPageView(dequeueOnly = false, addPageView = true) {
    GtmStore.pushHostToGtm();

    if (
      typeof window != 'undefined' &&
      GtmStore.pageStore != null &&
      GtmStore.authStore != null &&
      GtmStore.gtmReady
    ) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];

      if (GtmStore.tempEvents.length > 0) {
        for (let event of GtmStore.tempEvents.reverse()) {
          if (GtmStore.lastEvent !== null) {
            GtmStore.lastEvent.source = "24orebs.com";
          }
          // @ts-ignore
          window.dataLayer.push(GtmStore.lastEvent);
        }
        GtmStore.tempEvents = [];
      }
    }
    if (!dequeueOnly) {
      if (GtmStore.lastEvent !== null) {
        if (addPageView && !GtmStore.lastEvent.event) {
          GtmStore.lastEvent.event = 'pageview';
        }
        if (GtmStore.isPagePathNeeded(GtmStore.lastEvent)) {
          GtmStore.lastEvent.page_path = GtmStore.pagePath;
        }
        if (
          typeof window != 'undefined' &&
          GtmStore.pageStore != null &&
          GtmStore.authStore != null &&
          GtmStore.gtmReady
        ) {
          // @ts-ignore
          window.dataLayer.push(GtmStore.fillEvent(GtmStore.lastEvent));
        } else {
          GtmStore.tempEvents.push(GtmStore.fillEvent(GtmStore.lastEvent));
        }
        GtmStore.lastEvent = null;
        GtmStore.emptyPageView = false;
      } else {
        GtmStore.emptyPageView = true;
      }
    }
  }

  public static pushEvent(gtmEventData: GtmEventData, addPageView = true) {
    if (GtmStore.lastEvent !== null) {
      GtmStore.pushPageView(false, addPageView);
    }
    GtmStore.lastEvent = gtmEventData;
    if (GtmStore.emptyPageView || !addPageView || gtmEventData.page_path) {
      GtmStore.pushPageView(false, addPageView);
    }
  }

  public static pushProductPageEvent(product: GtmProduct) {
    const productPageEvent = {
      source: "24orebs.com",
      event: 'eec.productDetails',
      ab_session: this.getAbValue(),
      ab_monitor: this.cookiesStore? get(this.cookiesStore.cookies).cookie_ab_monitor : undefined,
      ecommerce: {
        currencyCode: 'EUR',
        detail: {
          products: GtmStore.createImpressions([product]),
        },
      },
    };

    GtmStore.pushHostToGtm();
    if (typeof window != 'undefined' && GtmStore.gtmReady) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(productPageEvent);
      GtmStore.productBatch = [];
      if (GtmStore.interval) clearInterval(GtmStore.interval);
    }
  }

  public static pushHomepageSlideEvent(slide: HomepageSlide, position: number = 0) {
    const event = {
      source: "24orebs.com",
      event: 'HP',
      category: (position > 0 ? (position + '_') : '') + 'HP_' + ('productCode' in slide ? slide.productCode : slide.title.slice(0, 30)),
      action: 'click',
      event_id: '40',
      HP: 'productCode' in slide ? slide.productCode : slide.title.slice(0, 30),
      HPLink: 'buttonLink' in slide ? (slide.buttonLink ? 'PaginaProdotto' : 'CTA') : 'Custom',
    }

    GtmStore.pushHostToGtm();
    if (typeof window != 'undefined' && GtmStore.gtmReady) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(event);
      if (GtmStore.interval) clearInterval(GtmStore.interval);
    }
  }

  public static pushHomepageSlideViewEvent(slide: HomepageSlide, position: number = 0) {
    const event = {
      source: "24orebs.com",
      event: 'HP',
      category: (position > 0 ? (position + '_') : '') + 'HP_' + ('productCode' in slide ? slide.productCode : slide.title.slice(0, 30)),
      action: 'view',
      event_id: '40',
      HP: 'productCode' in slide ? slide.productCode : slide.title.slice(0, 30),
      HPLink: 'buttonLink' in slide ? (slide.buttonLink ? 'PaginaProdotto' : 'CTA') : 'Custom',
    }

    GtmStore.pushHostToGtm();
    if (typeof window != 'undefined' && GtmStore.gtmReady) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(event);
      if (GtmStore.interval) clearInterval(GtmStore.interval);
    }
  }

  public static pushHomepageBannerViewEvent(title: string) {
    const event = {
      source: "24orebs.com",
      event: 'BBevent',
      category: "BannerHP",
      action: 'VIEW',
      event_id: '52',
      label: title,
    }

    GtmStore.pushHostToGtm();
    if (typeof window != 'undefined' && GtmStore.gtmReady) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(event);
      if (GtmStore.interval) clearInterval(GtmStore.interval);
    }
  }

  public static pushCardClickEvent(product: GtmProduct, courseUrl: string) {
    const cardClickEvent = {
      source: "24orebs.com",
      event: 'eec.productClick',
      ab_session: this.getAbValue(),
      ab_monitor: this.cookiesStore? get(this.cookiesStore.cookies).cookie_ab_monitor : undefined,
      ecommerce: {
        click: {
          actionField: {
            list: getPageType(GtmStore.pagePath),
          },
          products: GtmStore.createImpressions([product]),
        },
      },
    };

    if (GtmStore.productBatch.length > 0) {
      GtmStore.pushProductViewEvent();
    }

    GtmStore.pushHostToGtm();
    if (typeof window != 'undefined' && GtmStore.gtmReady) {
      // Send the product view event with the current batch impressions
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(cardClickEvent);
      GtmStore.productBatch = [];
      if (GtmStore.interval) clearInterval(GtmStore.interval);
    }
  }

  public static stashProduct(product: GtmProduct) {
    if (GtmStore.interval) {
      clearInterval(GtmStore.interval);
    }

    const isStashed = GtmStore.productBatch.some(
      (stashedProduct) =>
        stashedProduct.id === product.id &&
        stashedProduct.position === product.position
    );

    if (!isStashed) {
      GtmStore.productBatch.push(product);
      GtmStore.productBatch.sort((a, b) => a.position - b.position);
    }

    const sizeOfBatch = GtmStore.productBatch.length;
    if (sizeOfBatch >= GtmStore.MAX_SIZE) {
      GtmStore.pushProductViewEvent();
    }

    GtmStore.interval = setInterval(() => {
      if (GtmStore.productBatch.length > 0) {
        GtmStore.pushProductViewEvent();
      }
    }, GtmStore.TIME_INACTIVE * 1000);
  }

  private static createImpressions(
    products: GtmProduct[],
    includePagePath = false
  ) {
    return products.map((product) => {
      const impression = {
        ...product,
        ...(product.pagePath &&
          includePagePath && { list: getPageType(product.pagePath) }),
        brand: 'bs24',
      };

      delete impression.pagePath;
      return impression;
    });
  }

  private static pushProductViewEvent() {
    const productViewEvent = {
      source: "24orebs.com",
      event: 'eec.productImpressions',
      ab_session: this.getAbValue(),
      ab_monitor: this.cookiesStore? get(this.cookiesStore.cookies).cookie_ab_monitor : undefined,
      ecommerce: {
        currencyCode: 'EUR',
        impressions: GtmStore.createImpressions(GtmStore.productBatch, true),
      },
    };

    GtmStore.pushHostToGtm();
    if (typeof window != 'undefined' && GtmStore.gtmReady) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push(productViewEvent);
      GtmStore.productBatch = [];
      if (GtmStore.interval) clearInterval(GtmStore.interval);
    }
  }

  private static fillEvent(gtmEventData: GtmEventData): GtmEventData {

    if (
      typeof gtmEventData.category !== 'undefined' &&
      [GtmEventCategory.MENU_BAR, 'aiuto'].indexOf(gtmEventData.category.toLocaleLowerCase()) > -1
    ) {
      return gtmEventData;
    }
    if (
      !gtmEventData.bsid &&
      GtmStore.userInfo &&
      GtmStore.userInfo.id &&
      !GtmStore.isCookieEvent(gtmEventData)
    ) {
      gtmEventData.bsid = 'BS' + GtmStore.userInfo.id;
    }
    if (!gtmEventData.UserStatus && !GtmStore.isCookieEvent(gtmEventData)) {
      gtmEventData.UserStatus = GtmStore.isLoggedIn ? 'Logged' : 'Not Logged';
    }
    if (!gtmEventData.B2B && gtmEventData.category !== 'RichiestaInfo_B2b') {
      gtmEventData.B2B = GtmStore.companyStatus ? 'Active' : 'Not active';
    }
    if (!gtmEventData.CP) {
      gtmEventData.CP = GtmStore.cookieNoticeAccepted ? 'Accepted' : 'Denied';
    }

    if (GtmStore.isPageViewEvent(gtmEventData)) {
      gtmEventData.bsid =
        typeof gtmEventData.bsid !== 'undefined' ? gtmEventData.bsid : 'NOTSET';
      gtmEventData.Code =
        typeof gtmEventData.Code !== 'undefined' ? gtmEventData.Code : 'NOTSET';
      gtmEventData.Price =
        typeof gtmEventData.Price !== 'undefined'
          ? gtmEventData.Price
          : 'NOTSET';
    }

    return {
      ...gtmEventData,
      source: "24orebs.com",
      ab_session: this.getAbValue(),
      ab_monitor: this.cookiesStore? get(this.cookiesStore.cookies).cookie_ab_monitor : undefined,
    };
  }

  private static isPagePathNeeded(gtmEventData: GtmEventData): boolean {
    //TODO: Convert the Array and gtmEventData.category into GtmEventCategory
    if (
      !gtmEventData.page_path &&
      !(
        typeof gtmEventData.category !== 'undefined' &&
        [
          GtmEventCategory.MENU_BAR.toString(),
          GtmEventCategory.COOKIE.toString(),
        ].includes(gtmEventData.category)
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  private static isPageViewEvent(gtmEventData: GtmEventData): boolean {
    if (
      typeof gtmEventData.event !== 'undefined' &&
      gtmEventData.event === 'pageview'
    ) {
      return true;
    } else {
      return false;
    }
  }

  private static isCookieEvent(gtmEventData: GtmEventData): boolean {
    if (
      typeof gtmEventData.event !== 'undefined' &&
      gtmEventData.category === GtmEventCategory.COOKIE
    ) {
      return true;
    } else {
      return false;
    }
  }

  private static getAbValue(): string {
    if (typeof window != 'undefined') {
      const abB2bSession = localStorage.getItem('ab_session') + "_B2B";
      const abCtaOrientamento = localStorage.getItem('ab_cta_orientamento') + "_CTAORIENT";
      return abB2bSession + "," + abCtaOrientamento;
    }

    return 'A';
  }

  public static pushCookieBarEvent(stricktly_necessary_cookie: string, analytics_cookie: string, profilation_cookie: string) {

    const cookieBarEvent = {
      source: "24orebs.com",
      event: "consent_update",
      stricktly_necessary_cookie: stricktly_necessary_cookie,
      analytics_cookie: analytics_cookie,
      profilation_cookie: profilation_cookie,
    };

    if (typeof window != 'undefined' && GtmStore.gtmReady) {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push(cookieBarEvent);
      // @ts-ignore
      window.dataLayer.push({ event: "consent_updated" });
      if (GtmStore.interval) clearInterval(GtmStore.interval);
    }
  }
}
