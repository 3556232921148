import { CourseLightModel, CourseModel, SessionLightModel, SessionModel, stringToSlug } from '@24hbs/utils';

const NORMAL_SLUG = 'mba';
const ORIGINAL_SLUG = 'executive-mba/executive-master-in-business-administration';
const ORIGINAL_CATEGORY_SLUG = 'gestione-di-impresa-export-e-internazionalizzazione';

function isCourseOfTypeMBA(course: CourseModel | CourseLightModel | SessionLightModel | SessionModel) {
  if (course.slug === ORIGINAL_SLUG && stringToSlug(course.category) === ORIGINAL_CATEGORY_SLUG) {
    return true;
  }
  return false;
}

const MODULES1 = [
  {
    subtitle: 'MODULO 1',
    title: 'LEADERSHIP, EMPOWERMENT & CHANGE MANAGEMENT'
  },
  {
    subtitle: 'MODULO 2',
    title: 'ECONOMICS, ACCOUNTING & CONTROLLING'
  },
  {
    subtitle: 'MODULO 3',
    title: 'CORPORATE FINANCE'
  },
  {
    subtitle: 'MODULO 4',
    title: 'PROJECT MANAGEMENT, LEAN & AGILE METHODOLOGIES'
  },
  {
    subtitle: 'MODULO 5',
    title: 'STRATEGIC MARKETING MANAGEMENT'
  },
  {
    subtitle: 'MODULO 6',
    title: 'DIGITAL HR & PEOPLE MANAGEMENT'
  },
  {
    subtitle: 'MODULO 7',
    title: 'DIGITAL SUPPLY CHAIN MANAGEMENT & LOGISTICS'
  },
  {
    subtitle: 'MODULO 8',
    title: 'CORPORATE STRATEGY & NEW BUSINESS MODELS'
  }
];

const MODULES2 = [
  {
    subtitle: 'MODULO 9',
    title: 'INNOVATION & DIGITAL MANAGEMENT'
  },
  {
    subtitle: 'MODULO 10',
    title: 'BIG DATA, ARTIFICIAL INTELLIGENCE & BUSINESS ANALYTICS'
  },
  {
    subtitle: 'MODULO 11',
    title: 'CYBERSECURITY & DATA PROTECTION'
  },
  {
    subtitle: 'MODULO 12',
    title: 'ESG, SUSTAINABILITY STRATEGY & CIRCULAR ECONOMY'
  },
  {
    subtitle: 'MODULO 13',
    title: 'DOING BUSINESS IN? RISK COUNTRY ANALYSIS'
  }
];

const FACULTY =[
  {
    name: 'STEFANO DONNARUMMA',
    role: 'AMMINISTRATORE DELEGATO & DIRETTORE GENERALE',
    company: 'Ferrovie dello Stato Italiane',
    bio: `Inizia la sua carriera nel 1993 come stagista in Fiat Mirafiori, laureandosi contestualmente con borsa di studio dell’Associazione Tecnica dell’Automobile. Lavora nel settore della componentistica automotive e ferroviaria (Ruetgers Automotive, TMD Friction) occupandosi di manutenzione, testing, safety, industrializzazione, produzione. Nominato direttore di stabilimento e dirigente d’azienda nel 2002 a Brescia, passa poi alla produzione di locomotive (Bombardier) e di treni ad alta velocità (Alstom) e diventa Direttore Manufacturing per entrambe le multinazionali ferroviarie. Gestisce commesse per la costruzione delle LOCO E464 (traffico regionale) e la realizzazione della prima FrecciaRossa 500. Conduce la produzione del sito di Alstom a Savigliano nel periodo di gestione delle commesse di Pendolino, Cisalpino e EMU CHINA, Minuetto e Trento Malè. <br />
    Nel 2007 passa al settore Multiutility, come Direttore Operazioni e poi Presidente Operativo di Acea Distribuzione SpA. <br />
    Nel 2012 diviene Direttore Aviation e Accountable Manager degli aeroporti di Roma ADR - Fiumicino e Ciampino. <br />
    Nel 2015 diviene Direttore Reti e Calore, infrastrutture energetiche, del Gruppo A2A. <br />
    Nel 2017 è Amministratore Delegato del Gruppo Acea SpA. <br />
    Nel 2020 il governo italiano lo nomina Amministratore Delegato di Terna SpA.  Considerato tra i massimi esperti di gestione delle infrastrutture per i servizi pubblici, è stato Vice Presidente di Utilitalia e Presidente del GO 15. <br />
    Ha preso parte a diversi tavoli di lavoro e di crisi sia a livello nazionale (locale, regionale, governativo) che a livello internazionale. Ha svolto numerose attività di formazione collaborando con atenei come Università di Roma Tor Vergata, Luiss Guido Carli University, Pontificia Università della Santa Croce, MIP, ed altri istituti di alta formazione. È Commendatore al Merito della Repubblica Italiana. <br />
    Nel 2024 viene nominato Amministratore Delegato e Direttore Generale di Ferrovie dello Stato Italiane. <br />
    È Direttore Scientifico dell’Executive Master in Business Administration presso la 24ORE Business School.`,
    image: '/assets/images/mba/docenti/StefanoDonnarummaHQ.webp'
  },
  {
    name: 'CORRADO ASSENZA',
    role: 'PARTNER',
    company: 'Nagima Consulenza e Formazione srl',
    bio: `Il suo focus sono gli "economics" d’impresa ovvero tutto quello che riguarda il bilancio, il sistema di pianificazione e controllo, la pianificazione finanziaria, i sistemi di costing e la valutazione delle iniziative di business.<br>
          Il suo obiettivo è affiancare i clienti nel loro processo di cambiamento attraverso la progettazione di attività formative, di mentoring nonchè l’avvio di progetti consulenziali volti ad introdurre strumenti e processi che garantiscano un presidio più consapevole degli aspetti economici, finanziari e patrimoniali.<br>
          Ha operato in molti settori industriali (industria, servizi, bancario, commessa, ...) ed anche nel terzo settore.<br>
          È docente nelle principali Corporate University dei maggiori gruppi industriali e bancari italiani nel cui ambito si occupa dei percorsi formativi che interessano i talenti e lo sviluppo di laboratori di verticalizzazione dei temi economici sui processi operativi.<br>
          Inoltre, è referente scientifico e docente presso la 24ORE Business School nell’ambito dell’Executive Master in Business Adminstration e di altri Executive Master.`,
    image: '/assets/images/mba/docenti/Assenza.webp'
  },
  {
    name: 'LORENZO BASSI',
    role: 'PARTNER',
    company: 'Carter & Benson',
    bio: `Laureato Cum Laude in Management, Finance e International Business all’Università di Bergamo, Lorenzo Bassi mostra subito interesse verso temi quali Strategia, Organizzazione e HR. Nel 2015 entra in Carter & Benson, dove attraverso un rapido percorso di crescita arriva nel 2022 ad esserne Partner. Grazie ad un Master in Digital Entrepreneurship, ha rafforzato il focus sulla ricerca di Manager in area Digital e Strategy, sullo sviluppo di Assessment e progetti d’impatto Strategico-Organizzativo. È spesso coinvolto in progetti internazionali anche grazie alle molte collaborazioni con le principali multinazionali italiane e non solo. I suoi principali ambiti di attività riguardano il mondo del Digital, del Fashion, del Fast Moving e infine l’ambito delle start-up (spesso con contatto diretto con i Fondi d’investimento che le partecipano). Oltre a essere membro del Direttivo di AIDP Giovani, collabora con molti Master, da quelli di primo livello sino ad arrivare agli Executive MBA`,
    image: '/assets/images/mba/docenti/Bassi.webp'
  },
  {
    name: 'MASSIMO BULLO',
    role: 'MARKETING BRAND DIRECTOR',
    company: 'Vodafone Italy',
    bio: `Massimo Bullo è il Chief Marketing Officer di Vodafone Italia, con responsabilità delle strategie e piani marketing per la multinazionale inglese in Italia e del coordinamento di tutta la Tribe Marketing della Società. In precedenza è stato Head of Division Marketing and Digital Transformation di Samsung Italia assumendo anche la responsabilità commerciale del canale di vendita digital dell'azienda coreana. Prima di Samsung ha ricoperto il ruolo di Country Marketing Director NINTENDO Italia con responsabilità marketing e comunicazione per tutti i prodotti Software e Hardware della casa madre giapponese, esperienza maturata anche in ambito FMCG nel top management di HARIBO SpA dove ha ricoperto il ruolo di Head of Marketing and Communication per l'Italia con la responsabilità di tutte le strategie di marketing e brand della multinazionale tedesca. In precedenza ha lavorato come Senior Consultant presso la società di consulenza Orange Network, occupandosi di progetti di consulenza in ambito marketing e multichannel strategy per diverse aziende, e in FERRERO, prima all’interno dell’unità Trade Marketing Innovation, e poi nel Marketing Ferrero per la sede Italiana. Attualmente è Coordinatore Scientifico e docente di Master Executive e percorsi formativi in ambito Marketing e Brand Innovation.`,
    image: '/assets/images/mba/docenti/bullo.webp'
  },
  {
    name: 'CHIARA CATGIU',
    role: 'Manager Sustainability & Climate Change Services',
    company: 'KPMG',
    bio: `È Manager del Team Sustainability Services & Climate Change di KPMG in Italia.<br>
          È responsabile dello sviluppo dei servizi e delle soluzioni di Economia Circolare di KPMG Italia, consentendo ai clienti di sfruttare questa opportunità. Chiara è Subject Matter Expert in Circular Economy al Global Decarbonization Hub di KPMG International, e supporta i clienti nella definizione delle loro strategie e dei piani di implementazione dell'economia circolare per aiutarli a raggiungere i loro obiettivi legati al clima.<br>
          Le sue esperienze precedenti includono il lavoro sull'economia circolare come parte della Ellen MacArthur Foundation all’interno della Fashion Initiative e sull'economia circolare per l’edilizia presso la società di ingegneria Arup.<br>
          È laureata in Ingegneria dei materiali e delle nanotecnologie al Politecnico di Milano.<br>
          A partire dal 2022, è Membro del Consiglio Direttivo dell’Associazione Italiana per lo Sviluppo dell’Economia Circolare (AISEC).`,
    image: '/assets/images/mba/docenti/Catgiu.webp'
  },
  {
    name: 'ALESSANDRO CRAVERA',
    role: 'PARTNER',
    company: 'Newton S.p.A.',
    bio: `È Partner di Newton S.p.A., docente di Economia delle organizzazioni complesse presso ISIA Roma Design e di leadership e strategia aziendale presso la 24ORE Business School.<br>
          Nell’ambito delle sue attività di consulenza si occupa di “Management Innovation” ovvero di ripensare l’organizzazione, la gestione e la strategia d’impresa in coerenza con le dinamiche evolutive dei sistemi complessi e di supportare le imprese nella loro evoluzione.<br>
          È autore di numerose pubblicazioni in Italia e all’estero, tra cui "Competere nella Complessità" (ETAS, 2008), "The negentropic role of redundancy in the processes of value creation and extraction and in the development of competitiveness" (E:CO Organization & Complexity, 2012) e "Guida ai classici del management nell’era della complessità" (Il Sole 24 Ore, 2012).`,
    image: '/assets/images/mba/docenti/Cravera.webp'
  },
  {
    name: 'GIULIA DETOMATI',
    role: 'CEO',
    company: 'InVento Innovation Lab',
    bio: `Nel 2014 Giulia fonda InVento Innovation Lab, premiata B Corp in Italia, una delle "Best for the World B Corps" che aiuta aziende e istituzioni verso una transizione rigenerativa.<br>
          In qualità di CEO, è responsabile dello sviluppo di progetti con aziende multinazionali e progetti education. In particolare le sue competenze si concentrano sulla gestione, certificazione B Corp, pianificazione generale, design aziendale rigenerativo, SDG, cambiamento climatico, strategie di sostenibilità e economia circolare.<br>
          In occasione dell'Ashoka Changemaker Summit 2021, Giulia entra a far parte degli Ashoka Fellow, una rete di imprenditori sociali che ambiscono a un cambiamento a livello sistemico e di paradigma, per l'impegno dimostrato nell'ambito di progetti education in cui è impegnato InVento Lab.<br>
          È ingegnere ambientale, e ha arricchito la sua formazione negli Stati Uniti grazie al programma "Mind the Bridge" a San Francisco e "Social Entrepreneurship" della Stanford University. È stata mentore del "The Start Up School" e mentore del programma LAUNCH del MIT a Boston. Ha svolto un periodo di attività di educazione ambientale all'estero per il National Park Service del governo degli Stati Uniti presso il National Park Service all’Acadia National.`,
    image: '/assets/images/mba/docenti/GiuliaDetomatiCEO.webp'
  },
  {
    name: 'SIMONA EPIFANI',
    role: 'Docente di Corporate Finance, Investitore',
    company: 'Financial Advisor, Dottore Commercialista',
    bio: `Supporta aziende ed imprenditori in qualità di Financial Advisor indipendente nei processi di crescita e di sviluppo del proprio business su tematiche relative ad aspetti strategici di finanza aziendale, sia ordinaria che straordinaria (M&A e Private Equity). Ha lavorato per oltre 20 anni in primarie banche d'investimento e fondi di Private Equity, nonchè società di financial advisory internazionali quotate in borsa e primari gruppi industriali (ERG, Acciona). Laureata con lode all’Università Bocconi di Milano. <br>
          E’ stata Founding Partner & Investitore di diverse società tra cui Arché Corporate Finance, EQUIRON, AXIA Capital ed Epiphany.<br>
          Collabora da oltre 15 anni con la 24ORE Business School per cui svolge docenze nei Master Executive Strategic Corporate Finance e nei Master Full Time Fintech & Banking.`,
    image: '/assets/images/mba/docenti/EPIFANISIMONAFOTOCVCOLORI.webp'
  },
  {
    name: 'GABRIELE FAGGIOLI',
    role: 'PRESIDENTE',
    company: 'Clusit',
    bio: `È Presidente del Clusit (Associazione Italiana per la Sicurezza Informatica).<br>
          È Responsabile Scientifico dell’Osservatorio Cybersecurity & Data Protection del Politecnico di Milano e senior advisor degli Osservatori della Digital Innovation del Politecnico di Milano.<br>
          È Adjunct Professor del MIP - Politecnico di Milano.<br>
          È CEO di Digital360 S.p.A. e di Partners4innovation S.r.l. (società controllata da Digital360 S.p.A.).<br>
          È stato membro del Gruppo di Esperti sui contratti di cloud computing della Commissione Europea.<br>
          È specializzato in contrattualistica informatica e telematica, in information & telecommunication law, nel diritto della proprietà intellettuale e industriale e negli aspetti legali della sicurezza informatica, in progetti inerenti l’applicazione delle normative inerenti la responsabilità amminstrativa degli enti e nel diritto dell’editoria e del markerting.<br>
          Ha pubblicato diversi libri fra cui, da ultimo, “I contratti di cloud computing” (Franco Angeli), “I contratti per l’acquisto di servizi informatici” (Franco Angeli), “Computer Forensics” (Apogeo), “Privacy per posta elettronica e internet in azienda” (Cesi Multimedia) oltre ad innumerevoli articoli sui temi di competenza ed è stato relatore a molti seminari e convegni.`,
    image: '/assets/images/mba/docenti/Faggioli.webp'
  },
  {
    name: 'ATTILIO GERONI',
    role: 'GIORNALISTA, IL SOLE 24 ORE',
    company: 'Il Sole 24 ORE',
    bio: `Giornalista, analista geopolitico, è stato per 33 anni a Il Sole 24 Ore, dove ha lavorato come corrispondente da Varsavia, Francoforte e Parigi e come responsabile della redazione Esteri. Ha seguito per il giornale la caduta del muro di Berlino e la transizione politica ed economica dei Paesi dell'Est, da Francoforte ha raccontato l'avvento dell'era Merkel e da Parigi le vittorie di Emmanuel Macron alle presidenziali. Sempre per Il Sole 24 Ore è stato responsabile della redazione Mondo & Mercati, settimanale dedicato all'internazionalizzazione delle imprese. Laureato in Lettere Moderne alla Statale di Milano, ha frequentato l'Istituto per la formazione al giornalismo Carlo De Martino. Appassionato di fotografia e cinema. Audiofilo.`,
    image: '/assets/images/mba/docenti/Geroni.webp'
  },
  {
    name: 'PATRIZIA GIANGUALANO',
    role: 'Sustainability and Governance Advisor',
    company: 'Libero professionista',
    bio: `Laureata in Economia e Commercio all’Università Luigi Bocconi.<br>
          Ha iniziato la sua carriera nel mondo industriale con ruoli di responsabilità nell’area Finance e Marketing (Montedison e IBM) per poi muoversi verso la consulenza, dove ha consolidato la sua carriera professionale e sviluppato competenze nell’area strategy, governance, rischi e controlli.<br>
          Oggi, è membro del Consiglio di Amministrazione e Presidente dei rispettivi Comitati Rischi di Sea Aereporti e Epta nonchè consigliere indipendente di nomina Assogestioni di Mondadori.<br>
          È, ed è stata consigliere di primarie banche italiane con incarichi nei Comitati controllo interno, Sostenibilità, Remunerazioni, Rischi e ODV e in Comitati strategici e di reingegnerizzazione.<br>
          Durante il suo percorso lavorativo, ha lavorato anche come consulente (prima Atkearney e poi PwC) in Italia e all’estero a supporto di Imprese, Banche, Compagnie di Assicurazione e SGR, in prevalenza nell’area Strategy, e successivamente Rischi e Controlli; seguendo, in particolare, la redazione di Piani Industriali, Progetti di Corporate Governance, implementazioni di sistemi di controllo integrato e di gestione dei rischi, Operazioni di Carve Out, Fusioni ed Integrazioni, revisione di Modelli organizzativi e distributivi.<br>
          È membro del Consiglio Direttivo di Nedcommunity (l’associazione degli amministratori indipendenti) e si occupa di formazione e consulenza sulle tematiche della governance e della sostenibilità.<br>
          Collabora con ASviS nei rapporti con le imprese ed è membro del Comitato Scientifico del Master di 24ORE Business School rivolto a consiglieri di amministrazione e sindaci, nonchè in quello sulla gestione della sostenibilità aziendale. Svolge attività di induction per consiglieri di amministrazione, professionisti e imprenditori per conto di Assogestioni e Nedcommunity.<br>
          È coautore di "Sostenibilità in cerca di imprese" il primo libro sull’applicazione della rendicontazione non finanziaria.<br>
          Attraverso il proprio network integrated governance, ha avviato la raccolta e pubblicazione di storie di successo con l’obiettivo di far aumentare rapidamente ed esponenzialmente il numero di imprenditori e di manager che, oltre a praticare uno sviluppo sostenibile, assumono su di se un ruolo culturale aggiuntivo nella comunicazione di esperienze sostenibili e pratiche virtuose.`,
    image: '/assets/images/mba/docenti/PatriziaGiangualano.webp'
  },
  {
    name: 'PAOLO IACCI',
    role: 'PRESIDENTE',
    company: 'Eca Italia',
    bio: `Attualmente è Presidente di Eca Italia, ha la cattedra di "Gestione delle risorse umane" all'Università statale di Milano ed è anche Presidente di AIDP Promotion (Associazione per la Direzione del Personale).<br>
          Ha un lungo passato di dirigente industriale prima e bancario poi. Il suo ultimo incarico è stato quello di Presidente operativo di BCC Credito Consumo, dopo aver ricoperto il ruolo di Condirettore generale del gruppo Pride, attualmente Ericsson IT Solution & Services.<br>
          Ancor prima aveva ricoperto posizioni di Direttore HR e Organizzazione in Italtel, Reader's Digest, Banca Intesa e in ultimo di Iccrea Holding.<br>
          Negli anni è stato Presidente di alcune società nell'ambito dei servizi ICT e consigliere di amministrazione dell'Università Bocconi e di alcune altre società pubbliche e private.<br>
          Direttore responsabile del webmagazine Hr On Line e direttore scientifico del trimestrale Direzione del Personale, è collaboratore di Harvard Business Review e autore di numerosi volumi di management e sviluppo delle risorse umane.`,
    image: '/assets/images/mba/docenti/IACCI.webp'
  },
  {
    name: 'GIUSI MELONI',
    role: 'PMP® CSM - Formatrice e Counsellor Organizzativo',
    company: '',
    bio: `La sua formazione integra linguistica, letteratura e psicologia a cui si aggiunge un’ampia esperienza professionale nella comunicazione e nel lavoro per progetti, maturata lavorando per e con organizzazioni di diverse dimensioni e appartenenti differenti a settori e culture.<br>
          Il suo lavoro si basa su modelli validati e ricerche innovative per identificare e proporre soluzioni adeguate all’organizzazione e agli individui con cui lavora. I suoi workshop sono strutturati per essere altamente interattivi e fondono in un’unica esperienza sapere, saper fare e saper essere.<br>
          Si occupa prevalentemente di:<br>
          - Lavoro per progetti: cultura, organizzazioni, metodologie e comportamenti<br>
          - Diversità e inclusione<br>
          - Resilienza e intelligenza narrativa<br>
          La sua passione sono il Risk Management e i dilemmi etici di cui ha scritto ne "The Risk Management Handbook", edito da Kogan Press.<br>
          “Facilito lo sviluppo delle persone e delle organizzazioni perché raggiungano il loro pieno potenziale, attingendo dalla mia esperienza manageriale e consulenziale per creare uno spazio protetto e accogliente - sia esso fisico o virtuale - in cui gli individui e le organizzazioni possano apprendere, lavorare e sviluppare le proprie buone pratiche”.`,
    image: '/assets/images/mba/docenti/Meloni.webp'
  },
  {
    name: 'IVAN ORTENZI',
    role: 'CHIEF INNOVATION EVANGELIST',
    company: 'Bip',
    bio: `È Chief Innovation Evangelist del gruppo internazionale di Consulenza di direzione Bip.<br>
          Ha contribuito al successo dell'iniziativa imprenditoriale di Ars et Inventio, prima società di consulenza italiana focalizzata esclusivamente sui temi dell'innovazione strategica e della creatività aziendale. Questo percorso è stato riassunto nei suoi libri pubblicati con l'editore FrancoAngeli "Innovation + Management" e "Innovation Manager" che riassumono oltre vent'anni di esperienza vicino a CEO e Chief Innovation Officer in vari settori per progettare e implementare idee innovative e modelli innovativi di successo.<br>
          Co-Autore del libro "Intelligenza Artificiale" (FrancoAngeli).<br>
          All'attività di consulenza, ha unito l'attività di docenza in ambito innovation management presso le principali Università e Business School Italiane quali UniCal, Cattolica, Trieste e Pavia, per i corsi executive e gli MBA.`,
    image: '/assets/images/mba/docenti/ortenziprofile.webp'
  },
  {
    name: 'MICHELE PALUMBO',
    role: 'DIRETTORE SUPPLY CHAIN & EXECUTIVE ADVISOR',
    company: '',
    bio: `Professore a contratto presso l'Università Cattolica di Milano, docente di Operations e Supply Chain presso alcuni fra i principali atenei (Politecnico di Milano e di Torino, LIUC, Bocconi) e coordinatore scientifico presso 24 Ore Business School, dove coordina alcuni corsi master, consulente per le strategie e lo sviluppo di soluzioni logistiche innovative al servizio dei Clienti. Entrato in Bayer Italia nel 2010 per ricoprire la responsabilità dell'acquisto di servizi logistici e distributivi del gruppo, è stato Head of Supply Chain Management ed ha dato vita e guidato importanti progetti di natura strategica ed operativa anche a livello internazionale nell'ambito del settore lifescience. Partner in diverse società di consulenza direzionale, ha assunto la responsabilità dei servizi logistici centrali di uno dei principali grossisti farmaceutici italiani, il Gruppo Comifar, e dell'innovazione e della logistica della piattaforma servizi del gruppo chimico-farmaceutico Hoechst in Italia. Laureato a pieni voti in Bocconi con una tesi di ricerca sull'economia circolare e con una specializzazione al Politecnico di Milano in gestione industriale, è approdato alla faculty della SDA Bocconi, dove ha progettato e coordinato i corsi di formazione manageriale in ambito logistico dell'Area Tecnologia e Produzione.<br>
          "Innovatore seriale", in qualità di manager ed accademico offre una prospettiva poliedrica in grado di generare valore grazie a soluzioni tecnologicamente avanzate, sostenibili ed efficienti.<br>
          Empatico ed altruista, intellettualmente curioso e visionario, ha una famiglia numerosa e si dedica da sempre ad attività caritative e di volontariato. Per citarne alcune, è stato membro del Consiglio di Amministrazione del Banco Alimentare e partecipa attivamente ai principali osservatori sulla compliance delle attività logistiche e di trasporto.`,
    image: '/assets/images/mba/docenti/Palumbo.webp'
  },
  {
    name: 'ALESSIO PENNASILICO',
    role: 'INFORMATION & CYBER SECURITY ADVISOR',
    company: 'Clusit',
    bio: `Practice leader dell’Information & Cyber Security Advisory team di P4I, Security Evangelist, noto nell'hacker underground come -=mayhem=-, è internazionalmente riconosciuto come esperto dei temi legati alla gestione della sicurezza delle informazioni e delle nuove tecnologie. Per questa ragione partecipa da anni come relatore ai più rilevanti eventi di security italiani ed internazionali ed è stato intervistato dalle più prestigiose testate giornalistiche, radio e televisioni nazionali ed internazionali, oltre che essere regolarmente docente a corsi universitari e master. All’interno di P4I, per importanti Clienti operanti nei più diversi settori di attività, sviluppa progetti mirati alla riduzione dell’impatto del rischio cyber sul business aziendale, tenendo conto di compliance a norme e standard, della gestione del cambiamento nell’introduzione di nuovi processi ed eventuali tecnologie correlate. Credendo che il cyber risk sia un problema organizzativo e non un mero problema tecnologico, Alessio da anni aiuta il top management, lo staff tecnico e l’organizzazione nel suo complesso a sviluppare la corretta sensibilità in merito al problema, tramite sessioni di awareness, formazione e coaching.<br>
          Alessio è inoltre membro del Comitato Tecnico di Clusit, Membro del Consiglio Direttivo Nazionale di Associazione informatici professionisti; AIP, membro del Comitato di Schema UNI 11506 di Kiwa Cermet ed Intertek, Vice Presidente del Comitato di Salvaguardia per l’Imparzialità di LRQA, l’ente di certificazione dei Lloyd’s e Senior Advisor degli Osservatori del Politecnico di Milano.`,
    image: '/assets/images/mba/docenti/Pennasilico.webp'
  },
  {
    name: 'GIORGIO SBARAGLIA',
    role: 'INFORMATION & CYBER SECURITY ADVISOR',
    company: '',
    bio: `Ingegnere, laureato all'Università di Bologna, dopo esser stato per molti anni dirigente in una grande società di costruzioni italiana, è oggi Information & Cyber Security Advisor e DPO (Data Protection Officer). Si occupa di consulenza e formazione in sicurezza informatica e privacy. Su questi temi collabora da anni, in qualità di docente, con la 24ORE Business School. È Membro del Comitato Scientifico Clusit (Associazione Italiana per la Sicurezza Informatica) e certificato "Innovation Manager" da RINA. Ha pubblicato per GoWare i libri "GDPR kit di sopravvivenza", "Cybersecurity kit di sopravvivenza. Il web è un luogo pericoloso. Dobbiamo difenderci!" e "iPhone. Come usarlo al meglio. Scopriamo insieme tutte le funzioni e le app migliori". Collabora con Cybersecurity360 testata specialistica del gruppo Digital360 per la sicurezza informatica. Scrive anche per ICT Security Magazine, Agenda Digitale e per la rivista CLASS.La sua mission? Portare la consapevolezza ("awareness") dell’importanza della Cybersecurity nelle aziende e nelle persone, che delle aziende sono la forza. Perché, come sostiene: "La Cybersecurity prima di essere un problema tecnico è un problema culturale".`,
    image: '/assets/images/mba/docenti/Sbaraglia.webp'
  },
  {
    name: 'FRANCESCA SESIA',
    role: 'Responsible Marketing di BlackRock Italia, parte dell’Executive Committee italiano',
    company: 'BlackRock Italia',
    bio: `Chief Operating Officer di BlackRock in Italia, parte dell’Executive Committee italiano e prima Head of Marketing dal 2018. Prima di BlackRock, ha lavorato per più di 10 anni in A.T. Kearney, prestigiosa società di consulenza manageriale, dove ha guidato lo sviluppo del team di Financial Institution in Italia, con ingaggio in moltissimi progetti di strategia, organizzazione e trasformazione e innovazione digitale per alcune delle Banche e Istituzioni Finanziarie leader nel mercato italiano. Ha lavorato anche 3 anni come Responsabile del marketing strategico del Gruppo Cattolica Assicurazioni. Laureata in Ingegneria presso il Politecnico di Torino, premiata tra i migliori laureati dall’Unione Industriale di Torino. Sposata e mamma di Tommaso, 13 anni.`,
    image: '/assets/images/mba/docenti/BLACKROCK0050LAST.webp'
  },
  {
    name: 'LUCA SOLARI',
    role: 'FOUNDER',
    company: 'OrgTech',
    bio: `Professore ordinario di Organizzazione aziendale presso l’Università degli Studi di Milano e fondatore di OrgTech, società che traduce il Freedom Management in un programma di trasformazione innovativa di imprese e istituzioni. Presso l’Università degli Studi di Milano riveste anche il ruolo di Presidente di Fondazione Unimi e Direttore della Scuola di Giornalismo Walter Tobagi. Ha pubblicato numerosi articoli e libri, tra i quali Freedom Management che anticipa il cambiamento dei modelli organizzativi e di recente, con Paolo Iacci, il carteggio Purpose e Leadership ibrida, una riflessione sulla necessità di trasformazione dei modelli di gestione delle imprese. Ha consolidato negli anni un’esperienza a cavallo tra teoria e pratica su tutti i processi di trasformazione dell’organizzazione e della funzione gestione risorse umane.`,
    image: '/assets/images/mba/docenti/LucaSolarifoto.webp'
  },
  {
    name: 'LORENZO SOLIMENE',
    role: 'PARTNER KPMG ADVISORY – SUSTAINABILITY & CLIMATE CHANGE SERVICES',
    company: 'KPMG',
    bio: `Lorenzo ha realizzato il suo intero percorso professionale nel 2000 all’interno di KPMG occupandosi di corporate sustainability, risk management, climate change, intellectual capital management e corporate governance. Nel corso della sua carriera professionale si è specializzato nell'integrazione delle politiche di sostenibilità lungo le varie fasi della catena del valore in diversi ambiti: strategia, rischi e governance, misurazione delle performance, stakeholder engagement e reporting.<br>
          Docente presso l’Università Cattolica di Milano e l’Università Carlo Cattaneo. Componente del Gruppo di ricerca “Assurance sulle non financial information”- ASSIREVI, dell’Osservatorio Normativo AIIA (Associazione Italina Internal Auditor), Gruppo di lavoro sulla Sostenibilità dell’AIAF (Associazione Italiana Analisti Finanziari) e del Comitato scientifico – IID e del Comitato scientifico del master “La gestione della sostenibilità aziendale” –24 Ore Business School.  Partecipa ai principali tavoli tecnici sui temi della corporate sustainability ed autore di articoli e saggi sul tema. Autore di “Sostenibilità in cerca d’impresa” edizione EGEA.<br>
          Laureato con Lode presso l’Università degli Studi di Salerno e Master in “Decisioni economiche e Responsabilità Sociale” promosso dall’Università Carlo Cattaneo.`,
    image: '/assets/images/mba/docenti/Solimene.webp'
  },
  {
    name: 'ALESSANDRO VOLPE',
    role: 'HEAD OF DATA SCIENCE',
    company: 'BIP xTech Italy',
    bio: `I lead the Data Science practice in BIP xTech Italy, contributing to the technical management of the Data Science & AI practice, leading Competence Development initiatives and Machine Learning/AI programs for BIP's customers.`,
    image: '/assets/images/mba/docenti/VolpeAlessandro.webp'
  },
  {
    name: 'ANDREA VISMARA',
    role: 'CONSULENTE E FORMATORE DI PROJECT MANAGEMENT',
    company: 'Progetti Vivi',
    bio: `Consulente e formatore di Project Management presso aziende e business school in Europa e Medio Oriente sino dal 2002, è certificato PMP, PMI-PBA, Prince2, MSP, e ITIL. Ottenuto un MBA presso Profingest Management School (ora Bologna Business School), è stato docente nei programmi MBA dal 2002 al 2008. Dal 2009 è membro della faculty della 24ORE Business School e referente scientifico per tutti i master in Project Management. Senior Associate di Management Centre Europe (Bruxelles), emanazione europea della American Management Association, quale consulente e formatore. Consulente aziendale sui temi della innovazione di prodotto e processo e gestione di progetti internazionali nei settori ICT, Automotive, Trasporti, Banking, Assicurazioni, Finanza, Operations e Logistica, Luxury, Consulting, Pharma, Difesa e Aerospazio. Socio fondatore di aziende tecnologiche, con filiali in Germania, USA, Cina e Taiwan.`,
    image: '/assets/images/mba/docenti/VISMARAmin.webp'
  },
  {
    name: 'LAURA ZANFRINI',
    role: 'CEO',
    company: 'Zala Consulting srl',
    bio: `È la CEO di Zala Consulting srl, società di consulenza che ha fondato a fine 2015 per supportare le aziende nei progetti di digital transformation, avendo un’importante esperienza in change management e nell’impatto del mondo digitale su persone ed organizzazioni.<br>
          Dal 2016 collabora con la 24ORE Business School sui temi di digital change management, digital HR, digital leadership, board leadership, e dal 2017 con EY Business School, sui loro progetti di trasformazione e di innovazione.<br>
          In precedenza è stata managing director di CFMT Business School, di cui ha seguito lo start-up, people development manager in Pfizer, HR manager in Iri Infoscan.All’inizio della sua carriera ha lavorato in CFMT e in Unisys Italia.<br>
          Si è laureata in Bocconi in organizzazione del lavoro e ha frequentato Executive Program in Strategia ed innovazione a Berkeley, Harvard, Wharton e, nel 2019, in Intelligenza Artificiale a Stanford. È consigliera del Forum della Meritocrazia, dove segue il progetto Meritocrazia nelle organizzazioni, e membro del comitato scientifico della 24ORE Business School sulle tematiche di digital transformation e leadership & change management`,
    image: '/assets/images/mba/docenti/Zanfrini.webp'
  }
];

export const MBAUtils = {
  isCourseOfTypeMBA,
  NORMAL_SLUG,
  ORIGINAL_SLUG,
  ORIGINAL_CATEGORY_SLUG,
  MODULES1,
  MODULES2,
  FACULTY
};
