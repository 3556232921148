import { 
  PUBLIC_BASE_URL,
  PUBLIC_FONDO_NUOVE_COMPETENZE,
  PUBLIC_FORMAZIONE_INTERAZIENDALE_A_CATALOGO, 
  PUBLIC_FORMAZIONE_SU_MISURA,
  PUBLIC_HR_FOR_BREAKFAST,
  PUBLIC_LE_MASTERCLASS
  }
from '$env/static/public';
import { CourseModel, SessioncontactModel, SessionModel } from '@24hbs/utils';

const buildB2BFondoNuoveCompetenze = () => {
  let course: CourseModel = new CourseModel();
  course.title = 'Fondo Nuove Competenze';
  course.slug = 'b2b-fondo-nuove-competenze';
  course.courseTypeFull = 'B2B Fondo';
  course.category = 'b2b-fondo';
  course.idMainSession = 10000;
  let selectedSession: SessionModel = new SessionModel();
  selectedSession.productCode = PUBLIC_FONDO_NUOVE_COMPETENZE;
  selectedSession.title = 'Fondo Nuove Competenze';
  selectedSession.slug = 'b2b-fondo-nuove-competenze';
  selectedSession.courseTypeFull = 'Opportunità';
  selectedSession.category = 'b2b-opportunita';
  selectedSession.idSession = 10000;
  selectedSession.price = 0;
  selectedSession.previewUrl = '/assets/images/b2b/fondo-nuove-competenze.webp';
  selectedSession.brochureBooking = false;
  selectedSession.brochureAvailable = true;
  selectedSession.brochureUrl = `${PUBLIC_BASE_URL}assets/b2b-brochure/opportunita/BrochureFondoNuoveCompetenze.pdf`;
  selectedSession.priceNoVat = 0;
  selectedSession.discountPrice = 0;
  //selectedSession.startDate = new Date();
  selectedSession.endDate = new Date();
  selectedSession.sessionContacts = SessioncontactModel.fromJson({
    editorialContact: 'B2B Sales',
    editorialEmail: 'b2b-info@24orebs.com',
    editorialPhone: '0237929700',
    marketingContact: '',
    marketingEmail: '',
    marketingPhone: '',
    customercareContact: '',
    customercareEmail: '',
    customercarePhone: '',
  });
  course.addSession(selectedSession);
  return course;
};

export const B2B_FONDO_NUOVE_COMPETENZE = {
  productPage: '/b2b/opportunita/b2b-fondo-nuove-competenze',
  model: buildB2BFondoNuoveCompetenze(),
  downloadBrochureConfirmationPath: '/b2b/opportunita/b2b-fondo-nuove-competenze/brochure_download_thankyou',
};

const buildB2BFormazioneInteraziendaleACatalogo = () => {
  let course: CourseModel = new CourseModel();
  course.title = 'Formazione Interaziendale a catalogo';
  course.slug = 'b2b-offerta-formativa-a-catalogo-per-le-aziende';
  course.courseTypeFull = 'B2B Offerta';
  course.category = 'b2b-offerta';
  course.idMainSession = 10000;
  let selectedSession: SessionModel = new SessionModel();
  selectedSession.productCode = PUBLIC_FORMAZIONE_INTERAZIENDALE_A_CATALOGO;
  selectedSession.title = 'Formazione Interaziendale a catalogo';
  selectedSession.slug = 'b2b-offerta-formativa-a-catalogo-per-le-aziende';
  selectedSession.courseTypeFull = 'Offerta';
  selectedSession.category = 'b2b-offerta';
  selectedSession.idSession = 10000;
  selectedSession.price = 0;
  selectedSession.previewUrl = '/images/b2b/offerta_formativa_aziende.jpg';
  selectedSession.brochureBooking = false;
  selectedSession.brochureAvailable = true;
  selectedSession.brochureUrl = `${PUBLIC_BASE_URL}assets/b2b-brochure/offerta/BrochureFormazioneInteraziendaleACatalogo.pdf`;
  selectedSession.priceNoVat = 0;
  selectedSession.discountPrice = 0;
  //selectedSession.startDate = new Date();
  selectedSession.endDate = new Date();
  selectedSession.sessionContacts = SessioncontactModel.fromJson({
    editorialContact: 'B2B Sales',
    editorialEmail: 'b2b-info@24orebs.com',
    editorialPhone: '0237929700',
    marketingContact: '',
    marketingEmail: '',
    marketingPhone: '',
    customercareContact: '',
    customercareEmail: '',
    customercarePhone: '',
  });
  course.addSession(selectedSession);
  return course;
};

export const B2B_FORMAZIONE_INTERAZIENDALE_A_CATALOGO = {
  productPage: '/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende',
  model: buildB2BFormazioneInteraziendaleACatalogo(),
  downloadBrochureConfirmationPath: '/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende/brochure_download_thankyou',
};

const buildB2BFormazioneSuMisura = () => {
  let course: CourseModel = new CourseModel();
  course.title = 'Formazione su Misura';
  course.slug = 'b2b-formazione-su-misura';
  course.courseTypeFull = 'B2B Offerta';
  course.category = 'b2b-offerta';
  course.idMainSession = 10000;
  let selectedSession: SessionModel = new SessionModel();
  selectedSession.productCode = PUBLIC_FORMAZIONE_SU_MISURA;
  selectedSession.title = 'Formazione su Misura';
  selectedSession.slug = 'b2b-formazione-su-misura';
  selectedSession.courseTypeFull = 'Offerta';
  selectedSession.category = 'b2b-offerta';
  selectedSession.idSession = 10000;
  selectedSession.price = 0;
  selectedSession.previewUrl = '/images/b2b/offerta_formativa_aziende.jpg';
  selectedSession.brochureBooking = false;
  selectedSession.brochureAvailable = true;
  selectedSession.brochureUrl = `${PUBLIC_BASE_URL}assets/b2b-brochure/offerta/BrochureFormazioneSuMisura.pdf`;
  selectedSession.priceNoVat = 0;
  selectedSession.discountPrice = 0;
  //selectedSession.startDate = new Date();
  selectedSession.endDate = new Date();
  selectedSession.sessionContacts = SessioncontactModel.fromJson({
    editorialContact: 'B2B Sales',
    editorialEmail: 'b2b-info@24orebs.com',
    editorialPhone: '0237929700',
    marketingContact: '',
    marketingEmail: '',
    marketingPhone: '',
    customercareContact: '',
    customercareEmail: '',
    customercarePhone: '',
  });
  course.addSession(selectedSession);
  return course;
};

export const B2B_FORMAZIONE_SU_MISURA = {
  productPage: '/b2b/offerta/b2b-formazione-su-misura',
  model: buildB2BFormazioneSuMisura(),
  downloadBrochureConfirmationPath: '/b2b/offerta/b2b-formazione-su-misura/brochure_download_thankyou',
};

const buildB2BHRforBreakfast = () => {
  let course: CourseModel = new CourseModel();
  course.title = 'HR for Breakfast';
  course.slug = 'b2b-hr-for-breakfast';
  course.courseTypeFull = 'B2B Offerta';
  course.category = 'b2b-offerta';
  course.idMainSession = 10000;
  let selectedSession: SessionModel = new SessionModel();
  selectedSession.productCode = PUBLIC_HR_FOR_BREAKFAST;
  selectedSession.title = 'HR for Breakfast';
  selectedSession.slug = 'b2b-hr-for-breakfast';
  selectedSession.courseTypeFull = 'Offerta';
  selectedSession.category = 'b2b-offerta';
  selectedSession.idSession = 10000;
  selectedSession.price = 0;
  selectedSession.previewUrl = '/images/b2b/hr_for_breakfast_desktop.jpg';
  selectedSession.brochureBooking = false;
  selectedSession.brochureAvailable = true;
  selectedSession.brochureUrl = `${PUBLIC_BASE_URL}assets/b2b-brochure/offerta/BrochureHRforBreakfast.pdf`;
  selectedSession.priceNoVat = 0;
  selectedSession.discountPrice = 0;
  //selectedSession.startDate = new Date();
  selectedSession.endDate = new Date();
  selectedSession.sessionContacts = SessioncontactModel.fromJson({
    editorialContact: 'B2B Sales',
    editorialEmail: 'b2b-info@24orebs.com',
    editorialPhone: '0237929700',
    marketingContact: '',
    marketingEmail: '',
    marketingPhone: '',
    customercareContact: '',
    customercareEmail: '',
    customercarePhone: '',
  });
  course.addSession(selectedSession);
  return course;
};

export const B2B_HR_FOR_BREAKFAST = {
  productPage: '/b2b/offerta/b2b-hr-for-breakfast',
  model: buildB2BHRforBreakfast(),
  downloadBrochureConfirmationPath: '/b2b/offerta/b2b-hr-for-breakfast/brochure_download_thankyou',
};

const buildB2BLeMasterclass = () => {
  let course: CourseModel = new CourseModel();
  course.title = 'Le Masterclass';
  course.slug = 'b2b-le-masterclass';
  course.courseTypeFull = 'B2B Offerta';
  course.category = 'b2b-offerta';
  course.idMainSession = 10000;
  let selectedSession: SessionModel = new SessionModel();
  selectedSession.productCode = PUBLIC_LE_MASTERCLASS;
  selectedSession.title = 'Le Masterclass';
  selectedSession.slug = 'b2b-le-masterclass';
  selectedSession.courseTypeFull = 'Offerta';
  selectedSession.category = 'b2b-offerta';
  selectedSession.idSession = 10000;
  selectedSession.price = 0;
  selectedSession.previewUrl = '/images/b2b/le_masterclass_desktop.jpg';
  selectedSession.brochureBooking = false;
  selectedSession.brochureAvailable = true;
  selectedSession.brochureUrl = `${PUBLIC_BASE_URL}assets/b2b-brochure/offerta/BrochureLeMasterclass.pdf`;
  selectedSession.priceNoVat = 0;
  selectedSession.discountPrice = 0;
  //selectedSession.startDate = new Date();
  selectedSession.endDate = new Date();
  selectedSession.sessionContacts = SessioncontactModel.fromJson({
    editorialContact: 'B2B Sales',
    editorialEmail: 'b2b-info@24orebs.com',
    editorialPhone: '0237929700',
    marketingContact: '',
    marketingEmail: '',
    marketingPhone: '',
    customercareContact: '',
    customercareEmail: '',
    customercarePhone: '',
  });
  course.addSession(selectedSession);
  return course;
};

export const B2B_LE_MASTERCLASS = {
  productPage: '/b2b/offerta/b2b-le-masterclass',
  model: buildB2BLeMasterclass(),
  downloadBrochureConfirmationPath: '/b2b/offerta/b2b-le-masterclass/brochure_download_thankyou',
};