/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type WebsiteSessionData = {
    /**
     * The session referrer
     */
    referrer?: string;
    /**
     * The session campaign facebook click id
     */
    fbclid?: string;
    /**
     * The session campaign google click id
     */
    gclid?: string;
    /**
     * The session campaign source
     */
    utmCampaignSource?: string;
    /**
     * The session campaign content
     */
    utmCampaignContent?: string;
    /**
     * The history session unique identifier
     */
    webid?: string;
    /**
     * The session campaign awin click id
     */
    awin?: string;
    /**
     * The session campaign medium
     */
    utmCampaignMedium?: string;
    /**
     * The session campaign name
     */
    utmCampaignName?: string;
    /**
     * The type of source
     */
    type?: WebsiteSessionData.type;
    /**
     * The session campaign google click source
     */
    gclsrc?: string;
    /**
     * The session campaign term
     */
    utmCampaignTerm?: string;
};
export namespace WebsiteSessionData {
    /**
     * The type of source
     */
    export enum type {
        DIRECT = 'direct',
        ADV = 'adv',
        ORGANIC = 'organic',
    }
}

